/* General styles for the form container */
.contact {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: auto;
    font-family: Arial, sans-serif;
}

/* Styles for the form labels */
.contact form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 1rem;
    color: #333;
}

/* Styles for the form input fields */
.contact .field {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box;
}

/* Styles for the textarea */
.contact textarea.field {
    height: 150px;
    resize: vertical;
}

/* Styles for the submit button */
.contact button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.contact button:hover {
    background-color: #45a049;
}

/* Additional styling */
.contact h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.contact .required {
    color: red;
}

.contact .error {
    color: red;
    margin-bottom: 15px;
}

.contact .success {
    color: green;
    margin-bottom: 15px;
}

.contact {
    margin-top: 100px;
}
