/* body{
    background-color: #eae5e5;
}

.navbar-brand{
    padding-left: 10px;
}

.nav-item-1{
    padding-left: 1000px;
}

.nav-item-2{
    padding-left: 70px;
}  */

.navbar-brand {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.navbar-brand img {
    margin-right: 30px;
}
.navbar-brand span {
    font-size: 40px;
    font-weight: bold;
    font-family: myFont;
    letter-spacing: 2.5px;
    color:rgb(35, 36, 37);
}

.navbar{

    background-color: rgb(224, 221, 214);

}

  