body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #207885; */
  background-color: #C3B9AD;

}

@font-face { 
  font-family: YOUR FONT NAME; 
  src: url(http://sitename.com/wp-content/themes/themename/fonts/font-name.ttf); 
  font-weight: normal; 
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 

#id{
  margin-top:10px;
  display: flex;
}

#text{

  margin-left: 30px;
  margin-top:120px;
  text-align: left;
  font-size: 60px;
  text-align: center; 
  font-weight: bold;
  font-family: "Playfair Display", serif;
    color: rgb(0, 0, 0);
  line-height: 110px;


}

#icons{
  display: flex;
  

}

#hero{
  height:150 px;
}

#Dog-img{

  margin-left: 100px;
  margin-top:50px;
  margin-right: 20px;
  
}

#divtext{
  margin-left: 80px;
}

#con{
  background-color: rgb(224, 221, 214);
    border: none;
    width: 230px;
    height:65px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
}

a{
  margin-top:0;
  margin-left:75px;
}

*{
  scrollbar-width: none;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}